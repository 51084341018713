import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear("token");
    navigate("/login");
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-menu">
        <h4>Menu</h4>
        <ul>
          <li
            className={`${location.pathname === "/user-dashboard" && "active"}`}
          >
            <Link to="/user-dashboard">
              <DashboardIcon className="me-2" />
              Dashboard
            </Link>
          </li>
          {/* <li className={`${location.pathname === "/wallet" && "active"}`}>
            <Link to="/wallet">My Wallet</Link>
          </li> */}
          <li className={`${location.pathname === "/orders" && "active"}`}>
            <Link to="/orders">
              <ShoppingCartIcon className="me-2" />
              Orders
            </Link>
          </li>
          <li className={`${location.pathname === "/my-account" && "active"}`}>
            <Link to="/my-account">
              <AccountCircleIcon className="me-2" />
              Account Details
            </Link>
          </li>
          <li style={{ cursor: "pointer" }} onClick={handleLogout}>
            <LogoutIcon className="me-2" />
            Logout
          </li>
        </ul>
      </div>
      <div className="dashboard-content">{children}</div>
    </div>
  );
};

export default DashboardLayout;
