const IMAGES = {
  // LOGO
  //HOME
  logo: require("../img/logo.png"),
  coc: require("../img/home/coc.png"),
  banner: require("../img/home/banner.png"),
  banner1: require("../img/home/banner1.png"),
  banner2: require("../img/home/banner2.png"),
  banner3: require("../img/home/banner3.png"),
  safe: require("../img/products/safe.png"),
  ml: require("../img/products/ml.webp"),

  m1: require("../img/about/m1.png"),
  m2: require("../img/about/m2.png"),
  m3: require("../img/about/m4.webp"),
  m4: require("../img/about/m4.png"),
  /// PHONE SKIN
  pbanner: require("../img/products/banner2.webp"),
  // barcode
  barcode: require("../img/barcode.jpg"),
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
};

export default IMAGES;
