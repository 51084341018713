import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";

import "./StickyFooter.css";
import SearchContainer from "../SearchContainer";

const StickyFooter = () => {
  const { user } = useSelector((state) => state.user);
  const [search, setSearch] = useState(false);

  return (
    <div className="sticky-footer">
      <Link to="/">
        <div className="footer-item">
          <HomeIcon />
          Home
        </div>
      </Link>
      <Link to="/games">
        <div className="footer-item">
          <SportsEsportsIcon />
          Games
        </div>
      </Link>

      {!user && (
        <Link onClick={() => setSearch(!search)}>
          <div className="footer-item">
            <SearchIcon className="icon" />
            Search
          </div>
        </Link>
      )}
      {user && (
        <Link to="/orders">
          <div className="footer-item">
            <ShoppingCartIcon />
            Orders
          </div>
        </Link>
      )}
      {user ? (
        <Link to="/user-dashboard">
          <div className="footer-item">
            <AccountCircleIcon />
            Account
          </div>
        </Link>
      ) : (
        <Link to="/login">
          <div className="footer-item">
            <AccountCircleIcon />
            Login
          </div>
        </Link>
      )}
      <SearchContainer search={search} setSearch={setSearch} />
    </div>
  );
};

export default StickyFooter;
