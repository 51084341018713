import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useSelector } from "react-redux";
import "../Footer/Footer.css";

const Footer = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/919863052375">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        {(location.pathname === "/" ||
          location.pathname === "/user-dashboard" ||
          location.pathname === "/orders" ||
          location.pathname === "/my-account") && (
          <>
            <div className="footer-logo">
              <h1 className="m-0">
                {/* THOIHEN OFFICIAL<span className="ffca00">STORE</span> */}
                THOIHEN OFFICIAL STORE
              </h1>
            </div>
            <div className="row">
              <div className="col-6 col-sm-6 col-md-4 col-lg-4 mb-4">
                <h6>Important Links</h6>
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">Refund Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-4 col-lg-4 mb-4">
                <h6>Quick Links</h6>
                <ul>
                  {!user && (
                    <>
                      <li>
                        <Link to="/login">Login</Link>
                      </li>
                      <li>
                        <Link to="/register">Register</Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/track-order">Track Order</Link>
                  </li>
                </ul>
              </div>
              <div className="social-cont col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
                <h6>Follow Us</h6>
                <div className="social-media-links">
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/profile.php?id=100054351698137&mibextid=dGKdO6&paipv=0&eav=AfanRzeS6ooidlehli0JCv5y_JKEsWZ41wLN9wDqx7MuP--xLjIjtbYGZhJ-4XiceSE&_rdr"
                  >
                    <FacebookIcon className="icon" />
                  </Link>
                  <Link target="_blank" to="https://wa.me/919863052375">
                    <WhatsAppIcon className="icon" />
                  </Link>
                  <Link
                    target="_blank"
                    to="mailto:thoihen.customer.service@gmail.com"
                  >
                    <EmailIcon className="icon" />
                  </Link>
                </div>
              </div>
              <hr />
            </div>
          </>
        )}
        <span className="text-center">
          <small>
            All Rights Reserved © 2023 | THOIHEN STORE |{" "}
            <br className="d-block d-md-none d-lg-none" /> Designed & Developed
            by
            <Link
              style={{ color: "#ebef29", fontWeight: "500" }}
              target="_blank"
              to="https://aashirdigital.com"
            >
              ~aashirdigital
            </Link>
          </small>
        </span>
      </div>
    </React.Fragment>
  );
};

export default Footer;
