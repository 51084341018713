import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./confirmation.css";

export default function Confirmation() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('orderId');
   const type = searchParams.get('type') || 'manual';

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  // Define the API call function outside of `useEffect`
  // FOR MANUAL
  const checkOrderStatusManual = async () => {
    console.log("Checking Order Status");

    try {
      const response = await fetch(`https://thoihenofficial.com/api/manual/status?orderId=${orderId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      const data = await response.json();
      console.log("Order Status:", data);

      if (data.message === "Order Placed Successfully" || data.message === "Order already exists" || data.message === "exist order" ) {
        setMessage("Order placed successfully! Redirecting you to the dashboard in 2 seconds...");
        setTimeout(() => navigate(`/view-order/${orderId}`), 2000);
      } else {
        setMessage("Order failed! Redirecting you to the dashboard in 2 seconds...");
        setTimeout(() => navigate("/user-dashboard"), 2000);
      }
    } catch (error) {
      console.error("Error checking order status:", error);
      setMessage("An error occurred! Redirecting you to the dashboard in 2 seconds...");
      setTimeout(() => navigate("/user-dashboard"), 2000);
    } finally {
      setLoading(false);
    }
  };

  const checkOrderStatusAuto = async () => {
    console.log("Checking Order Status");

    try {
      const response = await fetch(`https://thoihenofficial.com/api/smile/status?orderId=${orderId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      const data = await response.json();
      console.log("Order Status:", data);

      if (data.message === "Order Completed Successfully" || data.message === "Order already exists" || data.message === "exist order" ) {
        setMessage("Order placed successfully! Redirecting you to the dashboard in 2 seconds...");
        setTimeout(() => navigate(`/view-order/${orderId}`), 2000);
      } else {
        setMessage("Order failed! Redirecting you to the dashboard in 2 seconds...");
        setTimeout(() => navigate("/user-dashboard"), 2000);
      }
    } catch (error) {
      console.error("Error checking order status:", error);
      setMessage("An error occurred! Redirecting you to the dashboard in 2 seconds...");
      setTimeout(() => navigate("/user-dashboard"), 2000);
    } finally {
      setLoading(false);
    }
  };

  // Only run this `useEffect` once on component mount
  useEffect(() => {
    if (orderId && type === "auto") {
      checkOrderStatusAuto();
    }else if(orderId){
      checkOrderStatusManual();
    }
  }, []); // Empty dependency array ensures it only runs once

  return (
    <div className="confirmation-container">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <div className="confirmation-message">
          <p>{message}</p>
        </div>
      )}
    </div>
  );
}
