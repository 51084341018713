import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import "./Wallet.css";
import IMAGES from "../img/image";
import axios from "axios";
import { message } from "antd";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [form, setForm] = useState({ email: "", amount: "" });
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);

  const generateOrderId = () => {
    const numbers = "01234567"; // 10 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const orderId = randomNumbers.join("");
    setOrderId(orderId);
  };
  useEffect(() => {
    generateOrderId();
  }, []);

  async function submitPayment() {
    if (form?.amount < 50) {
      return message.error("Enter amount greater than 50");
    }
    try {
      setLoading(true);
      const paymentObject = {
        client_txn_id: orderId,
        amount: form?.amount,
        p_info: "Wallet Topup",
        customer_name: form?.customer_name,
        customer_email: form?.customer_email,
        customer_mobile: form?.customer_mobile,
        redirect_url:
          "https://thoihenofficial.com/api/payment/check-wallet-payment-received",
      };
      const response = await axios.post(
        "/api/payment/add-payment-to-wallet",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data && response.data.payment_url) {
        window.location.href = response.data.payment_url;
        setLoading(false);
      } else {
        console.log("Unexpected response structure:", response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
    }
  }

  async function getUserPayments() {
    try {
      const res = await axios.post("/api/payment/get-user-payments", {
        email: user?.email,
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [e.target.name]: e.target.value });
    if (name === "amount") {
      if (value < 50) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      setForm((prev) => ({
        ...prev,
        customer_email: user?.email,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
      }));
      getUserPayments();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="wallet-dash">
          <div className="wallet-dash-card">Balance: {user?.balance}</div>
          <div className="wallet-dash-card" onClick={() => setTab(1)}>
            Add Money
          </div>
          <div className="wallet-dash-card" onClick={() => setTab(0)}>
            Transaction History
          </div>
        </div>

        {/* USER HISTORY */}
        {tab === 0 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {payments &&
                payments.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.amount}</td>
                      <td>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </td>
                      <td>{item?.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}

        {/* BARCODE  */}
        {tab === 1 && (
          <div className="add-money">
            <div className="txn-details">
              <span className="text-danger">
                Note: Your amount must be greater or equals to 50rs
              </span>
              <div className="form-fields mt-3 mb-2">
                <label htmlFor="">Amount</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter amount"
                  name="amount"
                  onChange={handleChange}
                  value={form?.amount}
                />
                <span className="text-danger">
                  {error && "Enter amount greater or equal to 50"}
                </span>
              </div>
              <button
                onClick={submitPayment}
                className="w-100 register-btn mt-2"
              >
                Pay Online
              </button>
            </div>
          </div>
        )}
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;
