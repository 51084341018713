import React from "react";
import IMAGES from "../../img/image";
import trusted from "../../img/home/trusted.svg";
import register from "../../img/home/register.svg";
import privacy from "../../img/home/privacy.svg";
import verified from "../../img/home/verified.svg";
import "./HowItWorks.css";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import DiscountIcon from "@mui/icons-material/Discount";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";

const HowItWorks = () => {
  return (
    <div className="container-fluid how-it-works">
      <div className="container before-footer">
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4 col-12 text-center mb-2 mb-md-0 mb-lg-0">
            <ElectricBoltIcon className="icon" />
            <h6 className="my-3 poppins">Instant digital delivery</h6>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-12 text-center mb-2 mb-md-0 mb-lg-0">
            <DiscountIcon className="icon" />
            <h6 className="my-3 poppins">Best price & discounts</h6>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 col-12 text-center mb-2 mb-md-0 mb-lg-0">
            <PsychologyAltIcon className="icon" />
            <h6 className="my-3 poppins">24/7 Support</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
