import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message } from "antd";

const AdminBarcodePayments = () => {
  const [payments, setPayments] = useState(null);
  const [oldPayments, setOldPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredPayments, setFilteredPayments] = useState(null); // Added filteredPayments state
  const [filter, setFilter] = useState("");

  async function getAllPayments() {
    try {
      setLoading(true);
      const res = await axios.get("/api/payment/get-all-barcode-payments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
        setOldPayments(res.data.data.reverse());
        setFilteredPayments(res.data.data.reverse()); // Set filteredPayments initially
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  async function filterPayments(name) {
    if (name === "all") {
      setFilteredPayments(oldPayments); // Update filteredPayments instead of payments
    } else {
      const filteringProducts = oldPayments.filter((item) => {
        return item.status === name;
      });
      setFilteredPayments(filteringProducts);
      console.log(name);
    }
  }

  async function handleUpdateStatus(id, email, amount, status) {
    if (status !== "pending") {
      try {
        const res = await axios.post(
          "/api/payment/update-payment",
          {
            id: id,
            email: email,
            amount: amount,
            status: status,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.success) {
          message.success(res.data.message);
          getAllPayments();
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getAllPayments();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Payments</h3>
      </div>
      <hr />
      <div className="admin-queries">
        <div className="d-flex justify-content-end pe-5 mb-5">
          <div className="form-fields">
            <select
              onChange={(e) => {
                setFilter(e.target.value);
                filterPayments(e.target.value);
              }}
              name="fitler"
              className="py-2"
            >
              <option value="all">All</option>
              <option value="success">Success</option>
              <option value="pending">Pending</option>
            </select>
          </div>
        </div>
        <table className="table query-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Amount</th>
              <th>Txn ID</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <div className="loader-container text-center">
                <span class="loader"></span>
              </div>
            ) : (
              <>
                {filteredPayments &&
                  filteredPayments.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.email}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.txnId}</td>
                      <td>
                        {new Date(item?.created).toLocaleString("default", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                        })}
                      </td>
                      <td>
                        {item.status === "pending" ? (
                          <select
                            value={item?.status}
                            name="status"
                            onChange={(e) =>
                              handleUpdateStatus(
                                item?._id,
                                item?.email,
                                item?.amount,
                                e.target.value
                              )
                            }
                          >
                            <option value="success">Success</option>
                            <option value="pending">Pending</option>
                            <option value="reject">Reject</option>
                          </select>
                        ) : item?.status === "reject" ? (
                          "Rejected"
                        ) : (
                          "Success"
                        )}
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </AdminLayout>
  );
};

export default AdminBarcodePayments;
